import React from "react"

const BusinessItem = ({
  img,
  title,
  paragraph,
}: {
  img: string
  title: string
  paragraph: string
}) => {
  return (
    <>
      <div className="p-4 md:w-1/2" data-aos="fade-up" >
        <div className="flex flex-col h-full  overflow-hidden p-2  rounded-lg  ease-linear transition-all duration-200   hover:shadow-lg cursor-pointer">
          <div className="w-10 h-10 mr-3 mb-3 inline-flex items-center justify-center flex-shrink-0">
            <img className="" src={img} />
          </div>
          <h2 className="text-textColor text-lg title-font font-medium mb-0">
            {title}
          </h2>
          <p className="leading-relaxed text-sm">{paragraph}</p>
        </div>
      </div>
    </>
  )
}

export default BusinessItem
