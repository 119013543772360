import React from "react"
import GovernmentImg from "../../images/government.png"
import NonProfitImg from "../../images/non-profit.png"
import DeveloperImg from "../../images/developer.png"
import RealEstateImg from "../../images/real-estate.png"

import BusinessItem from "./BusinessItem"
import SectionTitle from "../SectionTitle"

const BusinessPlatforms = () => {
  return (
    <>
      <SectionTitle
        titleHead="Product Users"
        title="Solutions for every type of"
        spanTag="Business Platforms "
        subTitle=""
      />
      <div className="flex flex-wrap -m-4">
        <BusinessItem
          img={`${GovernmentImg}`}
          title="Government Agencies"
          paragraph="From the management of small renovations to large, complex
          projects, the SEIN Terminal provides government agencies with the
          transparency, standardization needed to meet the most strict of
          compliance requirements and increasing the chance of delivering
          projects on-time and on budget."
        />
        <BusinessItem
          img={`${NonProfitImg}`}
          title="Non-Profit Organizations"
          paragraph="The SEIN Terminal allows non-profits to do more with less by
          providing organizational and project level customization that serves
          as a force multiplier. The use of the full suite of applications allows
          for projects to be executed with artificial intelligence that rivals
          human decision making."
        />
        <BusinessItem
          img={`${DeveloperImg}`}
          title="Private Developers"
          paragraph="With the use of the SEIN Terminal, private developers can scale
          operations without the need to take on the traditional exposure. The SEIN Terminal allows for the easy replication of standard
          business practices while affording the ability to make adjustments based on local markets."
        />
        <BusinessItem
          img={`${RealEstateImg}`}
          title="Corporations"
          paragraph="Managing the build out of corporate locations and/or franchises is
          complicated, even more so when they are done simulatenous, in
          different markets, with different vendors and team members. The
          SEIN Terminal puts all of those different stakeholders on the same
          system and provides the insights and recommendations based on
          exactly what is appropriate for that specific scenario."
        />
      </div>
    </>
  )
}

export default BusinessPlatforms
