import React from "react"
import ClientOne from "../images/client1.png"
import ClientTwo from "../images/client2.png"
import ClientThree from "../images/client3.png"
import ClientFour from "../images/client4.png"
import ClientFive from "../images/client5.png"
import SectionTitle from "./SectionTitle"

const clients = [
  { id: 1, logo: ClientOne },
  { id: 2, logo: ClientThree },
  { id: 3, logo: ClientFour },
  { id: 4, logo: ClientTwo },
  { id: 5, logo: ClientFive },
]

const Partners = () => {
  return (
    <>
      <section className="relative body-font w-full pt-10 pb-2 md:pb-8">
        <div className="container px-4 max-w-6xl mx-auto items-center flex flex-wrap">
          <SectionTitle
            titleHead="Our Clients"
            title="Some Organizations that"
            spanTag="Trust Us"
            customClass="text-center md:w-full xl:w-full"
          />
          <div className="flex flex-wrap items-center w-full mb-2 md:mb-12">
            {clients.map(client => (
              <div
                key={client.id} data-aos="fade-up"
                className={`w-1/2 md:w-1/2 lg:w-1/5 p-0 
                          border border-l-0 border-t-0 border-b-0 border-gray-200 
                          border-opacity-90 grayscale hover:grayscale-0 
                ${client.id > 4 && "border-b-0" }
                ${(client.id === 5 || client.id === 10) && "border-r-0"}`}
              >
                <div className="py-8 px-6 cursor-pointer">
                  <img className="mx-auto" src={client.logo} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Partners
