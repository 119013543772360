import * as React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import CustomHeader from "../components/CustomHeader"
import BusinessPlatforms from "../components/BusinessPlatforms"
import SectionTitle from "../components/SectionTitle"
import DemoBookingSection from "../components/DemoBookingSection"
import Partners from "../components/partners"

const About = () => (
  <Layout>
    <Seo title="About" />
    <CustomHeader title="About Us" />
    <section id="about" className="body-font mt-2 md:mt-10 pt-10">
      <div className="container px-4  max-w-6xl mx-auto items-center flex flex-wrap">
        <SectionTitle
          titleHead="About Us"
          title="Our mission is to redefine"
          spanTag="how real estate is done"
        />
        <div className="max-w-6xl mx-auto items-center flex flex-wrap">
          <div className="md:w-2/3 mb-2 md:pr-12 pr-0 text-justify space-y-6">
            <p>
              Our story begins in 2006 when the founder and CEO, Aziz Housseini,
              began working at a financial institution as a software engineer.
              Two years later he began working as a real estate developer for a
              non-profit in Baltimore city in the middle of the 2008 financial
              crisis. After more than a decade of developing real-estate
              projects of all shapes, sizes and complexity levels, one recurring
              theme appeared, the lack of a unified system across the industry.
              This lack of an operating system gave a way to an inefficient,
              opaque and fragmented industry.
            </p>
            <p>
              It is from this observation and a mortal disdain for inefficiency
              that SEIN Tech Partners was created, to provide the
              real-estate industry with an operating system (similar to
              Microsoft Windows) that can be used by everyone involved in
              real-estate. In addition to the operating system, dubbed the SEIN
              Terminal, SEIN Tech Partners also develops core applications
              that push the industry to be more transparent, inclusive and
              efficient.
            </p>
            <p>
              <span className="text-emerald-400 mr-1 font-semibold">
                SEIN Tech Partners
              </span>
              develops software that powers the creation of real-estate. Our
              tools make real-estate creation more collaborative, integrated and
              replicable. We leverage machine learning, a cloud infrastructure
              and industry best practices to deliver on our goal of
              democratizing the real-estate creation process in favor of
              efficiency, transparency and scalability.
            </p>
            <p>
              <span className="text-emerald-400 mr-1 font-semibold">
                SEIN Terminal
              </span>
              is a gateway to our ecosystem which provides access to our
              proprietary softwares, tools, research and resources for our
              clients/partners, users, and vendors.Additionally, it can also
              integrate various third party tools and software.
            </p>
            <p>
              In addition to improving the SEIN ecosystem, STP serves as a
              change agent to organizations looking to digitally transform their
              real estate operations.
            </p>
          </div>
          <div className="w-full md:w-1/3 flex items-center justify-center">
            <div className="bg-gradient-to-r from-green-500 to-blue-500 rounded-full p-2">
              <div className="bg-gray-900 text-white font-bold rounded-full h-64 w-64 flex flex-col items-center  justify-center">
                <p>Efficiency</p>
                <p>Transparency</p>
                <p>Scalibility</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="relative body-font w-full pt-10 pb-3 md:pb-20">
      <div className="container px-4  max-w-6xl mx-auto items-center flex flex-wrap">
        <BusinessPlatforms />
      </div>
    </section>
    <DemoBookingSection />
    <Partners />
  </Layout>
)

export default About
